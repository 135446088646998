import React from 'react'
import { apiClient } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  ButtonGroup
} from 'reactstrap'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'
import Header from '../../../components/Headers/Header.js'
import ErrorSummary, { resolveError } from '../../../components/Errors/ErrorSummary'
import Loader from '../../../components/Loader/Loader'
import { saveImage } from '../../../utils/imageHelper'
import ImageUpload from '../../../components/Upload/ImageUpload'
import EventDatePicker from '../../../components/EventDatePicker/EventDatePicker'
import disabilityImg from '../../../assets/img/event/create-feature-disability.svg'
import disabilityWcImg from '../../../assets/img/event/create-feature-dis-wc.svg'
import wcImg from '../../../assets/img/event/create-feature-wc.svg'
import hearingAidImg from '../../../assets/img/event/create-feature-hearing.svg'
import foodImg from '../../../assets/img/event/create-feature-food.svg'
import Login from '../Login'


const moment = require('moment')

class EventEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: null,
      errors: null,
      loading: true,  // Show Loader by default as object will be null on load
      notFound: false,
      dateError: false,
      timeRangeError: false,
      locationFeatureButtons: [
        {
          key: 'Wheelchair',
          displayText: 'Handikappanpassat',
          img: disabilityImg,
          atLocation: false
        },
        {
          key: 'WheelchairWC',
          displayText: 'Handikapptoalett',
          img: disabilityWcImg,
          atLocation: false
        },
        {
          key: 'WC',
          displayText: 'Toalett',
          img: wcImg,
          atLocation: false
        },
        {
          key: 'HearingAid',
          displayText: 'Hörslinga',
          img: hearingAidImg,
          atLocation: false
        },
        {
          key: 'Food',
          displayText: 'Mat och dryck',
          img: foodImg,
          atLocation: false
        }
      ]
    }

    this.getData = this.getData.bind(this)
    this.putData = this.putData.bind(this)
    this.handleInput = this.handleInput.bind(this)

    this.image = {
      changed: false,
      base64Image: null,
      imageUrl: null,
      imagePath: null
    }

    this.endpoint = '/events'
    this.listRoute = '/admin/events'
    this.imagesEndpoint = '/images'

  }

  async componentDidMount() {
    const token = JSON.parse(sessionStorage.getItem('accessToken'))
    if (token) {
      await this.getData(token, this.props.match.params.eventId)
    } else {
      this.props.history.push('/auth/login')
      window.location.reload()
    }
  }

  async getData(accessToken, membertypeId) {
    try {
      await this.setState({
        loading: true
      })
      const response = await apiClient.get(`${this.endpoint}/${membertypeId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      await this.setState({
        object: {
          ...this.state.object,
          ...response.data,
          start: moment(response.data.start).format('YYYY-MM-DD'),
          startTime: moment(response.data.start).format('HH:mm'),
          endTime: moment(response.data.end).format('HH:mm'),
        },
      })
      this.image.imagePath = this.state.object.imagePath
      this.image.imageUrl = this.state.object.imagePath && `${apiClient.defaults.baseURL}${this.imagesEndpoint}/${this.state.object.imagePath}` 
      // handle locationFeatures
      if (this.state.object?.locationFeatures.length > 0) {
        console.log("Location features:", this.state.object?.locationFeatures);

        var myFeatures = this.state.object?.locationFeatures;

        myFeatures.forEach((f) => {
          console.log(f)
          this.toggelLocationFeature(f)
        })
      }

      

    } catch (error) {
      const errors = resolveError(error)
      await this.setState({
        errors,
        notFound: error?.response?.status === 404
      })
    } finally {
      await this.setState({
        loading: false
      })
    }
  }

  async putData(accessToken, membertypeId, data) {
    try {
      this.image = this.image.changed ? await saveImage(accessToken, this.image, '#input-file') : this.image
      if (data.eventType.toLowerCase() === 'physical') {
        if (data.location?.length > 0) {
          var locationFeatures = []
          this.state.locationFeatureButtons.forEach((button) => {
            if (button.atLocation) {
              locationFeatures.push(button.key)
            }
          })

          data.locationFeatures = locationFeatures
        } else {
          data.locationFeatures = []
        }
        data.link = null;
      } else {
        data.locationFeatures = [];
        data.location = null;
      }
      const tempData = {
        ...data,
        imagePath: this.image?.imageUrl,
        start: moment(`${data.start}T${data.startTime}`, 'YYYY-MM-DDTHH:mm:ss').toISOString(),
        end: moment(`${data.start}T${data.endTime}`, 'YYYY-MM-DDTHH:mm:ss').toISOString()
      }
      await apiClient.put(`${this.endpoint}/${membertypeId}`,
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.history.push(this.listRoute)
      window.location.reload()
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors
      })
    }
  }

  async handleInput(key, value) {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        [key]: value
      }
    }))
    
  }

  frmIsValid({ title, description, start, startTime, endTime, fee, spots, location, link, timeRangeError, dateError }) {
    //console.log('validation', title, description, start, startTime, endTime, fee, spots, location, link, timeRangeError, dateError)
    var haslocationOrlink = (this.state.object.eventType.toLowerCase() === 'physical' ? location?.length : link?.length);

    return title.length > 0 && description.length > 0
      && fee.toString().length > 0 && spots.toString().length > 0
      && start.length > 0 && startTime.length > 0 && endTime.length > 0 && haslocationOrlink && !timeRangeError && !dateError

      
  }

  toggelLocationFeature = (locationFeatureName) => {
    const items = this.state.locationFeatureButtons
    const obj = this.state.locationFeatureButtons.find(f => f.key === locationFeatureName)
    const index = this.state.locationFeatureButtons.indexOf(obj)
    items[index].atLocation = !items[index].atLocation

    this.setState({
      locationFeatureButtons: items
    })
  }

  getLocationFeatureButtons() {
    return (
      <Row>
        <Col lg="12">
          <FormGroup>
            <ButtonGroup>
              {this.state.locationFeatureButtons.map(feature => {
              return (
                <React.Fragment>
                  <Button
                    color="primary"
                    outline
                    key={feature.key} 
                    value={feature.key}
                    onClick={() => this.toggelLocationFeature(feature.key)}
                    active={feature.atLocation}
                  >
                    <img src={feature.img}/>{feature.displayText}
                  </Button>
                </React.Fragment>
              );
            })}
            </ButtonGroup>
          </FormGroup>
        </Col>
      </Row>
        
    );
  }

  handleEventType() {
    if (this.state.object.eventType.toLowerCase() === "digital") {
      return (
        <Row>
          <Col lg="12">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-link"
              >
                Länk (Digitalt event)
              </label>
              <Input
                className="form-control-alternative"
                id="input-link"
                type="text"
                value={this.state.object.link}
                onChange={(e) => this.handleInput('link', e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      );
    } else {
      return <>
        <Row>
          <Col lg="12">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-location"
              >
                Adress
              </label>
              <Input
                className="form-control-alternative"
                id="input-location"
                type="text"
                value={this.state.object.location}
                onChange={(e) => this.handleInput('location', e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        {this.getLocationFeatureButtons()}
      </>
    }

  }

  toggelEventType = (eventTypeFilter) => {
    var obj = this.state.object;
    obj.eventType = eventTypeFilter;
    this.setState({
      object: obj
    });
    
  };


  showDeleteConfirm = async (accessToken, event) => {
    const { confirm } = Modal

    confirm({
      title: 'Är du säker på att du vill radera eventet?',
      icon: <ExclamationCircleOutlined />,
      content: event.title,
      okType: 'danger',
      okText: 'Ja',
      cancelText: 'Avbryt',
      onOk: async () => {
        await this.delObject(accessToken, event.id)
        this.props.history.push(this.listRoute)
        window.location.reload()
      },
      onCancel() { },
    })
  }

  async delObject(accessToken, eventId) {
    try {
      await apiClient.delete(`${this.endpoint}/${eventId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      const response = await apiClient.get(`${this.endpoint}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      await this.setState({
        array: response.data,
      })
      sessionStorage.setItem(this.storageKey, JSON.stringify(response.data))
    } catch (error) {
      resolveError(error)
    }
  }

  cancelThisEdit = () => {
    this.props.history.push(this.listRoute)
    window.location.reload()
  }

  render = () => {
    const { object } = this.state
    const isValid = object && this.frmIsValid({...this.state.object, timeRangeError: this.state.timeRangeError, dateError: this.state.dateError})
    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Ändra</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <ImageUpload imageUrl={this.image?.imageUrl} onChange={() => this.image.changed = true} />
                        <ButtonGroup>
                          <Button
                            color="primary"
                            outline
                            value='digital'
                            onClick={() => this.toggelEventType('digital')}
                            active={this.state.object.eventType.toLowerCase() === 'digital'}
                          >
                            Digitalt event 
                          </Button>
                          <Button
                            color="primary"
                            outline
                            value='physical'
                            onClick={() => this.toggelEventType('physical')}
                            active={this.state.object.eventType.toLowerCase() === 'physical'}
                          >
                            Fysiskt event
                          </Button>
                        </ButtonGroup>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                              >
                                Namn
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-title"
                                type="text"
                                value={object.title}
                                onChange={(e) => this.handleInput('title', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-region"
                              >
                                Region
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-region"
                                type="text"
                                value={object.region?.name ?? ''}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-description"
                              >
                                Om evenemang
                              </label>
                              <textarea
                                className="form-control form-control-alternative"
                                id="input-description"
                                rows="7"
                                value={object.description}
                                onChange={(e) => this.handleInput('description', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <EventDatePicker {...object} 
                          timeRangeError={this.state.timeRangeError}  
                          dateError={this.state.dateError}
                          onChange={this.handleInput} 
                          onDateChange={(e) => this.setState({dateError: e.error})}
                          onTimeRangeChange={(e) => this.setState({timeRangeError: e.error})}
                        />
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-fee"
                              >
                                Pris (kr)
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-fee"
                                type="number"
                                min="0"
                                value={object.fee}
                                onChange={(e) => this.handleInput('fee', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-spots"
                              >
                                Max antal (st)
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-spots"
                                type="number"
                                min="1"
                                max="9999"
                                value={object.spots}
                                onChange={(e) => this.handleInput('spots', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        {this.handleEventType()}


                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <Row>
                        <Col lg="3">
                        <Button
                          color="success"
                          type="submit"
                          onClick={(e) => { e.preventDefault(); this.putData(this.props.accessToken, this.props.match.params.eventId, this.state.object) }}
                          disabled={!isValid}
                        >
                          Spara
                          </Button>
                        </Col>
                        <Col lg="6"></Col>
                        <Col lg="2" >
                        <Button
                          color="danger"
                          type="submit"
                          onClick={(e) => { e.preventDefault(); this.showDeleteConfirm(this.props.accessToken, this.state.object) }}
                        >
                          Radera Event
                          </Button>
                        </Col>
                        <Col lg="1" >
                          <Button
                            color="default"
                            type="submit"
                            onClick={(e) => { e.preventDefault(); this.cancelThisEdit() }}
                            disabled={!isValid}
                          >
                            Avbryt
                          </Button>
                        </Col>
                      </Row>

                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      if(this.state.loading) {
        return (
          <>
            <Header />
            <Container className="mt-4" fluid>
              <h3 style={{textAlign: 'center', paddingTop: 25}}>
                <Loader items={5} color="danger" type="grow" size="sm" />
              </h3>
            </Container>
          </>
        )
      } else {
        return (
          <>
            <Header redColor={true} />
            <Container className="mt-4" fluid>
              <h1>
                Åh nej!
                <br />
                {this.state.notFound ? (
                  <>Kunde tyvärr inte hitta eventet.</>
                ) : this.state.errors ? (
                  <ErrorSummary errors={this.state.errors} />
                ) : (
                  <>Ett okänt fel inträffade. Var vänlig försök igen.</>
                )}
              </h1>
            </Container>
          </>
        )
      }
    }
  }
}

export default withRouter(EventEdit)
