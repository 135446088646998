import React from 'react'
import { apiClient } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Button,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter
} from 'reactstrap'
import Header from '../../../components/Headers/Header.js'
import { resolvePagingOffset } from '../../../utils/ListHelper'
import { resolveError } from '../../../components/Errors/ErrorSummary'

class PublicChatGroupsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      array: [],
      pageIndex: 0,
      pageSize: 15,
      orderBy: '',
      desc: null,
    }

    this.changePageIndex = this.changePageIndex.bind(this)
    this.onSort = this.onSort.bind(this)
    this.renderSortIcon = this.renderSortIcon.bind(this)
    this.navigate = this.navigate.bind(this)

    this.endpoint = '/public-chats'
    this.storageKey = 'public-chats'
    this.route = 'public-chat-groups'
    this.imagesEndpoint = '/images'

  }

  async componentDidUpdate(prevProps, prevState) {
    if ((this.state.pageIndex !== prevState.pageIndex) || (this.state.orderBy !== prevState.orderBy) || (this.state.desc !== prevState.desc)) {
      try {
        const skip = resolvePagingOffset(this.state.pageIndex, this.state.pageSize)
        const response = await apiClient.get(`${this.endpoint}/list?take=${this.state.pageSize}&skip=${skip}${this.state.orderBy ? `&orderBy=${this.state.orderBy}&desc=${this.state.desc}` : ''}`, {
          headers: {
            Authorization: `Bearer ${this.props.accessToken}`,
          },
        })
        await this.setState({
          array: response.data,
        })
        sessionStorage.setItem(this.storageKey, JSON.stringify(response.data))
      } catch (error) {
        resolveError(error)
      }
    }

    if (this.props.accessToken !== prevProps.accessToken) {
      try {
        const skip = resolvePagingOffset(this.state.pageIndex, this.state.pageSize)
        const response = await apiClient.get(`${this.endpoint}/list?take=${this.state.pageSize}&skip=${skip}${this.state.orderBy ? `&orderBy=${this.state.orderBy}&desc=${this.state.desc}` : ''}`, {
          headers: {
            Authorization: `Bearer ${this.props.accessToken}`,
          },
        })
        await this.setState({
          array: response.data,
        })
        sessionStorage.setItem(this.storageKey, JSON.stringify(response.data))
      } catch (error) {
        resolveError(error)
      }
    }
  }

  componentDidMount() {
    const array = JSON.parse(sessionStorage.getItem(this.storageKey))

    if (array !== null) {
      this.setState({
        array: array,
      })
    }
  }

  async changePageIndex(number, data, type) {
    if (type === 'sub') {
      if (number >= 0) {
        this.setState({
          pageIndex: number
        })
      }
    } else if (type === 'add') {
      if (number >= 0 && data.length >= this.state.pageSize) {
        this.setState({
          pageIndex: number
        })
      }
    }
  }

  async onSort(user, sortKey) {
    let desc = this.state.desc === null ? false : !this.state.desc
    await this.setState({
      orderBy: sortKey,
      desc,
    })
  }

  async navigate(item) {
    this.props.history.push(`/admin/${this.route}/${item.id}`)
    window.location.reload()
  }

  renderSortIcon(columnName) {
    return this.state.orderBy === columnName ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null
  }

  render = () => {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Alla publika chattgrupper</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href={`/admin/${this.route}/create`}
                        size="sm"
                      >
                        Skapa ny
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Symbol</th>
                      <th onClick={e => this.onSort(e, 'title')} scope="col">Namn {this.renderSortIcon('title')}</th>
                      <th onClick={e => this.onSort(e, 'regionName')} scope="col">Region {this.renderSortIcon('regionName')}</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.array.length > 0
                      ? this.state.array.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.imagePath ? <img className="img-in-listtable" alt="Bild" src={`${apiClient.defaults.baseURL}${this.imagesEndpoint}/${item.imagePath}`} /> : null}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.title}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.region?.name ?? ''}
                                </span>
                              </td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem href={`/admin/${this.route}/${item.id}`}>
                                      Ändra
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })
                      : null}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {this.state.pageIndex !== 0 ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                            >
                              {(this.state.pageIndex).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem className="active">
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex, this.state.array, 'add') }}
                        >
                          {(this.state.pageIndex + 1).toString()}
                        </PaginationLink>
                      </PaginationItem>
                      {this.state.array.length >= this.state.pageSize ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                            >
                              {(this.state.pageIndex + 2).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem>
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  };
}

export default withRouter(PublicChatGroupsList)
