import React from 'react'
import { apiClient } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Button,
  ButtonGroup
} from 'reactstrap'
import Header from '../../../components/Headers/Header.js'
import { resolvePagingOffset } from '../../../utils/ListHelper'
import { resolveError } from '../../../components/Errors/ErrorSummary'

class ReportsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      array: [],
      active: [],
      shouldShowAll: false,
      pageIndex: 0,
      pageSize: 15,
      orderBy: '',
      desc: null,
      severities: [
        { key: "NotEvaluated",  value: "Ej bedömd" },
        { key: "NotOffensive",  value: "Bedöms ej som överträdelse" },
        { key: "Minor",         value: "Minde överträdelse" },
        { key: "Major",         value: "Grov överträdelse" }
      ],
      reasons: [
        { key: "Other", value: "Annan anledning" },
        { key: "CodeOfConductViolation", value: "Regelbrott" },
        { key: "IllegalBehavior", value: "Olagligt beteende" }
      ],
      reportTypes: [
        { key: "Event", value: "Event" },
        { key: "ChatMessage", value: "Chat-meddelande" }
      ],
    }

    this.changePageIndex = this.changePageIndex.bind(this)
    this.onSort = this.onSort.bind(this) // <-- Sorting??
    this.renderSortIcon = this.renderSortIcon.bind(this)
    this.navigate = this.navigate.bind(this)

    this.endpoint = '/chat-violations/reports'
    this.storageKey = 'reports'
    this.route = 'reports'

  }

  async componentDidUpdate(prevProps, prevState) {
    if ((this.state.pageIndex !== prevState.pageIndex) || (this.state.orderBy !== prevState.orderBy) || (this.state.desc !== prevState.desc)) {
      try {
        const skip = resolvePagingOffset(this.state.pageIndex, this.state.pageSize)
        const response = await apiClient.get(`${this.endpoint}?take=${this.state.pageSize}&skip=${skip}${this.state.orderBy ? `&orderBy=${this.state.orderBy}&desc=${this.state.desc}` : ''}`, {
          headers: {
            Authorization: `Bearer ${this.props.accessToken}`,
          },
        })
        await this.setState({
          array: response.data,
          active: response.data.filter(coc => coc.isActive),
        })

        sessionStorage.setItem(this.storageKey, JSON.stringify(response.data))
      } catch (error) {
        resolveError(error)
      }
    }

    if (this.props.accessToken !== prevProps.accessToken) {
      try {
        const skip = resolvePagingOffset(this.state.pageIndex, this.state.pageSize)
        const response = await apiClient.get(`${this.endpoint}?take=${this.state.pageSize}&skip=${skip}${this.state.orderBy ? `&orderBy=${this.state.orderBy}&desc=${this.state.desc}` : ''}`, {
          headers: {
            Authorization: `Bearer ${this.props.accessToken}`,
          },
        })

        await this.setState({
          array: response.data,
          active: response.data.filter(coc => coc.isActive),
        })
        sessionStorage.setItem(this.storageKey, JSON.stringify(response.data))
      } catch (error) {
        resolveError(error)
      }
    }

  }

  componentDidMount() {
    const data = JSON.parse(sessionStorage.getItem(this.storageKey))

    if (data !== null) {
      this.setState({
        array: data,
        active: data.filter(coc => coc.isActive)
      })
    }
  }

  async changePageIndex(number, data, type) {
    if (type === 'sub') {
      if (number >= 0) {
        this.setState({
          pageIndex: number
        })
      }
    } else if (type === 'add') {
      if (number >= 0 && data.length >= this.state.pageSize) {
        this.setState({
          pageIndex: number
        })
      }
    }
  }

  async onSort(user, sortKey) {
    let desc = this.state.desc === null ? false : !this.state.desc
    await this.setState({
      orderBy: sortKey,
      desc,
    })
  }

  async navigate(item) {
    console.log('ITEM clicked', item)
    this.props.history.push(`/admin/${this.route}/${item.codeOfConductViolationId}`)
    //window.location.reload()
  }

  renderSortIcon(columnName) {
    return this.state.orderBy === columnName ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null
  }

 
  toggelShowAllFeature(button) {
    console.log('button clicked', button)
    var value = button == 'All' 
    this.setState({
      shouldShowAll: value
    })
  }

  render = () => {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="6">
                      <h3 className="mb-0" >
                        <ButtonGroup>
                          <Button
                            color="primary"
                            outline
                            value='ActiveOnly'
                            onClick={() => this.toggelShowAllFeature('ActiveOnly')}
                            active={!this.state.shouldShowAll}
                          >
                            Visa aktiva ärenden
                          </Button>
                          <Button
                            color="primary"
                            outline
                            value='All'
                            onClick={() => this.toggelShowAllFeature('All')}
                            active={this.state.shouldShowAll}
                          >
                            Visa alla (inkl hanterade)
                          </Button>

                        </ButtonGroup>

                      </h3>
                    </Col>

                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr >
                      <th className="sortable" onClick={e => this.onSort(e, 'reportType')} scope="col">Typ {this.renderSortIcon('reportType')}</th>
                      <th className="sortable" onClick={e => this.onSort(e, 'authorName')} scope="col">Anmäld användare {this.renderSortIcon('authorName')}</th>
                      <th className="sortable" onClick={e => this.onSort(e, 'authorPhoneNumber')} scope="col">Telefonnummer {this.renderSortIcon('authorPhoneNumber')}</th>
                      <th className="sortable" onClick={e => this.onSort(e, 'reason')} scope="col">Anledning {this.renderSortIcon('reason')}</th>
                      <th className="sortable" onClick={e => this.onSort(e, 'severity')} scope="col">Bedömning {this.renderSortIcon('severity')}</th>
                      <th className="sortable" onClick={e => this.onSort(e, 'created')} scope="col">Datum {this.renderSortIcon('created')}</th>
                      <th scope="col">Anmält meddelande </th>
                      <th scope="col">Bild </th>
                      <th scope="col">Antal anm.</th> {/*ej fält i databasen. Kan ej sorteras*/}
                      <th className="sortable" onClick={e => this.onSort(e, 'reporterName')} scope="col">Anmäld av {this.renderSortIcon('reporterName')}</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.array.filter(a => this.state.shouldShowAll || a.isActive).length > 0
                      ? this.state.array.filter(a => this.state.shouldShowAll || a.isActive).map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr className="clickable" style={item.isActive ? { backgroundColor: '#0040DD', color: 'white' } : {}} >
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {this.state.reportTypes.map(s => s.key == item.reportType ? s.value : '')}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }} style={{ width: '100px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                <span className="mb-0 text-sm">
                                  {item.authorName}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.authorPhoneNumber}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {this.state.reasons.map(s => s.key == item.reason ? s.value : '')}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {this.state.severities.map(s => s.key == item.severity ? s.value : '')}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {new Date(item.createdDt).toLocaleDateString('sv-SE', {
                                    year: 'numeric', month: 'numeric', day: 'numeric'
                                  }).replace(/ /g, '-')}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }} style={{ width: '200px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                <span className="mb-0 text-sm">
                                  {item.text?.length > 40 ? item.text?.substring(0, 40) + ' ...' : item.text}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.picturePath ? <img className="img-in-listtable" alt="Bild" src={apiClient.defaults.baseURL + '/images/' + item.picturePath} style={{ aspectRatio: '1', height: '75px' }} /> : null}
                                  {console.log('API-Klient: ', apiClient.defaults.baseURL)}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.totalViolations}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.reporterName}
                                </span>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })
                      : null}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {this.state.pageIndex !== 0 ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                            >
                              {(this.state.pageIndex).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem className="active">
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex, this.state.array, 'add') }}
                        >
                          {(this.state.pageIndex + 1).toString()}
                        </PaginationLink>
                      </PaginationItem>
                      {this.state.array.length >= this.state.pageSize ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                            >
                              {(this.state.pageIndex + 2).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem>
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  };
}

export default withRouter(ReportsList)
