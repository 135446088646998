import React from 'react'
import {
  Spinner
} from 'reactstrap'

const Loader = ({ items = 5, color = 'danger', type = 'grow', size = '' }) => {
  var loaderItems = []
  for (var i = 0; i < items; i++) {
    loaderItems.push(<Spinner key={i} color={color} type={type} size={size}></Spinner>)
  }

  return loaderItems
}

export default Loader
