import React from 'react'
import {
  Row,
  Col,
  FormGroup,
  Input,
  FormText
} from 'reactstrap'
var moment = require('moment')

const EventDatePicker = (props) => {
  const {start, startTime, endTime, dateError, timeRangeError, onChange, onDateChange, onTimeRangeChange} = props

  const handleInput = (key, value) => {
    onChange(key, value)
    
    if(key === 'start') {
      validateDate({ startDate: value, startTime: startTime })
    }
    if(key === 'startTime') {
      validateTimeRange({ startDate: start, startTime: value, endTime: endTime})
    }
    if(key === 'endTime') {
      validateTimeRange({ startDate: start, startTime: startTime, endTime: value})
    }
  }

  const validateDate = ({ startDate, startTime}) => {

    if (startDate) {
      const now = moment()
      const start = moment(startDate)
      const dateIsBefore = start?.isBefore(now, 'day') ?? false

      if(dateIsBefore) {
        onDateChange({error: dateIsBefore})
        return
      } 

      if(startTime && (start?.isSame(now, 'day') ?? false)) {
        const mStartDateTime = moment(start.format('YYYY-MM-DD') + 'T' + startTime, 'YYYY-MM-DDTHH:mm:ss')
        onDateChange({error: mStartDateTime?.isBefore(now) ?? false})
        return
      } 

      onDateChange({error: false})
    }
  }

  const validateTimeRange = ({ startDate, startTime, endTime }) => {
    if(startTime && endTime) {
      const mStart = moment('1970-01-01T' + startTime, 'YYYY-MM-DDTHH:mm:ss')
      const mEnd = moment('1970-01-01T' + endTime, 'YYYY-MM-DDTHH:mm:ss')

      onTimeRangeChange({error: mStart?.isAfter(mEnd) ?? false})
    }

    if (startDate && startTime) {
      validateDate({startDate, startTime})
    }
  }

  return (
    <Row>
      <Col lg="2">
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor="input-endDate"
            style={{ color: dateError ? '#f5365c' : '' }}
          >
            Datum
          </label>
          <Input
            className="form-control-alternative"
            id="input-start"
            type="date"
            min={moment().format('YYYY-MM-DD')}
            placeholder="yyyy-mm-dd"
            value={start}
            onChange={(e) => handleInput('start', e.target.value)}
          />
          <FormText color="danger" style={{ display: dateError ? 'block' : 'none' }}>
            * Eventet kan inte skapas bakåt i tiden
          </FormText>
        </FormGroup>
      </Col>
      <Col lg="2">
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor="input-startDateTime"
            style={{ color: timeRangeError ? '#f5365c' : '' }}
          >
            Starttid
          </label>
          <Input
            className="form-control-alternative"
            id="input-startDateTime"
            type="time"
            value={startTime}
            placeholder="hh:mm"
            onChange={(e) => handleInput('startTime', e.target.value)}
          />
          <FormText color="danger" style={{ display: timeRangeError ? 'block' : 'none' }}>
            * Starttid/Sluttid: Eventet kan inte sluta innan det har börjat
          </FormText>
        </FormGroup>
      </Col>
      <Col lg="2">
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor="input-endDateTime"
            style={{ color: timeRangeError ? '#f5365c' : '' }}
          >
            Sluttid
          </label>
          <Input
            className="form-control-alternative"
            id="input-endDateTime"
            type="time"
            placeholder="hh:mm"
            value={endTime}
            onChange={(e) => handleInput('endTime', e.target.value)}
          />
        </FormGroup>
      </Col>
    </Row>
  )
}
 
export default EventDatePicker