import React from 'react'
import { apiClient } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap'
import Header from '../../../components/Headers/Header.js'
import { Select, Image } from 'antd'
import 'antd/dist/antd.css'
import ErrorSummary, { resolveError } from '../../../components/Errors/ErrorSummary'
import { saveImage } from '../../../utils/imageHelper'
import ImageUpload from '../../../components/Upload/ImageUpload'

class PublicChatGroupCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: {
        title: '',
        description: '',
        image: '',
        regionCode: ''
      },
      errors: null,
      regions: []
    }

    this.postData = this.postData.bind(this)
    this.handleInput = this.handleInput.bind(this)

    this.image = {
      changed: false,
      base64Image: null,
      imageUrl: null,
      imagePath: null
    }
  }

  async componentDidMount() {
    try {
      const regionsResponse = await apiClient.get('/regions')

      await this.setState({
        regions: regionsResponse.data
      })
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors
      })
    }
  }

  async postData(accessToken, data) {
    try {
      this.image = await saveImage(accessToken, this.image, '#input-file')
      const tempData = {
        ...data,
        imagePath: this.image?.imageUrl
      }

      await apiClient.post('/public-chats',
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.history.push('/admin/public-chat-groups')
      window.location.reload()
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors
      })
    }
  }

  async handleInput(key, value) {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        [key]: value
      }
    }))
  }

  render = () => {
    const { object } = this.state
    const { Option } = Select
    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Skapa ny</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        {this.image?.imageUrl ? <Image height={200} src={this.image?.imageUrl} /> : ''}
                        <ImageUpload imageUrl={this.image?.imageUrl} onChange={() => this.image.changed = true} />                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Titel
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-name"
                                type="text"
                                value={object.title}
                                onChange={(e) => this.handleInput('title', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Beskrivning
                              </label>
                              <textarea
                                className="form-control form-control-alternative"
                                id="input-name"
                                rows="7"
                                value={object.description}
                                onChange={(e) => this.handleInput('description', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6" sm="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Region
                              </label>
                              <Select
                                showSearch
                                className="form-control-alternative form-control"
                                placeholder="Sök efter region..."
                                optionFilterProp="children"
                                onChange={(value) => this.handleInput('regionCode', value)}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {this.state.regions.map((item, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <Option value={item.code}>{item.name}</Option>
                                    </React.Fragment>
                                  )
                                })}
                              </Select>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        <Button
                          color="success"
                          type="submit"
                          onClick={(e) => { e.preventDefault(); this.postData(this.props.accessToken, this.state.object) }}
                        >
                          Skapa
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      return null
    }
  };
}

export default withRouter(PublicChatGroupCreate)
