import React from 'react'
import { apiClient } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Button,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Modal,
  ModalBody
} from 'reactstrap'
import Header from '../../../components/Headers/Header.js'
import { resolvePagingOffset } from '../../../utils/ListHelper'
import { Modal as ModalAntd } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { resolveError } from '../../../components/Errors/ErrorSummary'
import UserSearchComponent from '../../../components/Users/UserSearchComponent'

class AdminUsersList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      array: [],
      pageIndex: 0,
      pageSize: 15,
      orderBy: '',
      desc: null,
      modalVisible: false,
      grantingRole: false
    }

    this.delObject = this.delObject.bind(this)
    this.changePageIndex = this.changePageIndex.bind(this)
    this.onSort = this.onSort.bind(this)
    this.renderSortIcon = this.renderSortIcon.bind(this)
    this.navigate = this.navigate.bind(this)

    this.endpoint = '/users'
    this.storageKey = 'admin-users'
    this.route = 'admin-users'
  }

  async componentDidUpdate(prevProps, prevState) {
    if ((this.state.pageIndex !== prevState.pageIndex) || (this.state.orderBy !== prevState.orderBy) || (this.state.desc !== prevState.desc)) {
      await this.fetchData()
    }

    if (this.props.accessToken !== prevProps.accessToken) {
      await this.fetchData()
    }
  }

  fetchData = async () => {
    try {
      const skip = resolvePagingOffset(this.state.pageIndex, this.state.pageSize)
      const response = await apiClient.get(`${this.endpoint}?isAdmin=true&take=${this.state.pageSize}&skip=${skip}${this.state.orderBy ? `&orderBy=${this.state.orderBy}&desc=${this.state.desc}` : ''}`, {
        headers: {
          Authorization: `Bearer ${this.props.accessToken}`,
        },
      })
      await this.setState({
        array: response.data,
      })
      sessionStorage.setItem(this.storageKey, JSON.stringify(response.data))
    } catch (error) {
      resolveError(error)
    }
  }

  componentDidMount() {
    const array = JSON.parse(sessionStorage.getItem(this.storageKey))

    if (array !== null) {
      this.setState({
        array: array,
      })
    }
  }

  async changePageIndex(number, data, type) {
    if (type === 'sub') {
      if (number >= 0) {
        this.setState({
          pageIndex: number
        })
      }
    } else if (type === 'add') {
      if (number >= 0 && data.length >= this.state.pageSize) {
        this.setState({
          pageIndex: number
        })
      }
    }
  }

  showDeleteConfirm = async (accessToken, user) => {
    const { confirm } = ModalAntd

    confirm({
      title: 'Är du säker på att du vill ta bort användarens administratörsroll?',
      icon: <ExclamationCircleOutlined />,
      content: `${user.firstName} ${user.lastName} (${user.phoneNumber})`,
      okType: 'danger',
      okText: 'Ja',
      cancelText: 'Avbryt',
      onOk: async () => {
        await this.delObject(accessToken, user.id)
      },
      onCancel() {},
    })
  }

  async delObject(accessToken, userId) {
    try {
      await apiClient.put(`${this.endpoint}/${userId}/role/admin/revoke`, null, { headers: { Authorization: `Bearer ${accessToken}` }})
      await this.fetchData()
    } catch (error) {
      resolveError(error)
    }
  }

  onSort(e, sortKey) {
    let desc = this.state.desc === null ? false : !this.state.desc
    this.setState({
      orderBy: sortKey,
      desc,
    })
  }

  async navigate(item) {
    return item
  }

  renderSortIcon(columnName) {
    return this.state.orderBy === columnName ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null
  }

  toggleModal = () => {
    this.setState({
      modalVisible: !this.state.modalVisible
    })
  }

  grantAdminRole = async (userId) => {
    try {
      this.setState({ grantingRole: true })
      await apiClient.put(`/users/${userId}/role/admin/grant`, null, { headers: {Authorization: `Bearer ${this.props.accessToken}`} })
      this.fetchData()
    } catch (error) {
      resolveError(error)
    } finally {
      this.setState({grantingRole: false })
      this.toggleModal()
    }
  }

  render = () => {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Alla administratörer</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={this.toggleModal}
                      >
                        Lägg till
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th onClick={e => this.onSort(e, 'firstName')} scope="col">Förnamn {this.renderSortIcon('firstName')}</th>
                      <th onClick={e => this.onSort(e, 'lastName')} scope="col">Efternamn {this.renderSortIcon('lastName')}</th>
                      <th onClick={e => this.onSort(e, 'phoneNumber')} scope="col">Telefonnummer {this.renderSortIcon('phoneNumber')}</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.array.length > 0
                      ? this.state.array.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.firstName}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.lastName}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.phoneNumber}
                                </span>
                              </td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      href="#"
                                      onClick={(e) => { e.preventDefault(); this.showDeleteConfirm(this.props.accessToken, item) }}
                                    >
                                      Ta bort administratör
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })
                      : null}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {this.state.pageIndex !== 0 ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                            >
                              {(this.state.pageIndex).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem className="active">
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex, this.state.array, 'add') }}
                        >
                          {(this.state.pageIndex + 1).toString()}
                        </PaginationLink>
                      </PaginationItem>
                      {this.state.array.length >= this.state.pageSize ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                            >
                              {(this.state.pageIndex + 2).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem>
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
              <Modal isOpen={this.state.modalVisible} toggle={this.toggleModal} backdrop="static" keyboard={false} unmountOnClose={true}>
                <ModalBody>
                  <UserSearchComponent
                    accessToken={this.props.accessToken}
                    title='LÄGG TILL ADMINISTRATÖR'
                    primaryBtnText='Lägg till'
                    confirmText='Är du säker på att du vill lägga till användaren som administratör?'
                    loading={this.state.grantingRole}
                    onUserSelected={this.grantAdminRole}
                    onClosed={this.toggleModal}
                  />
                </ModalBody>
              </Modal>
            </div>
          </Row>
        </Container>
      </>
    )
  };
}

export default withRouter(AdminUsersList)
