import React from 'react'
import { Container, Col } from 'reactstrap'

class Header extends React.Component {
  render() {
    if (this.props.imgUrl) {
      return (
        <>
          <div style={{ background: `url(${this.props.imgUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} className="header pb-6 pt-5 pt-md-8">
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className={this.props.redColor ? 'header pb-6 pt-5 pt-md-8 bg-gradient-danger' : this.props.text ? 'header pb-6 pt-5 pt-md-8 bg-gradient-primary' : 'header pb-6 pt-5 pt-md-8'}>
            {this.props.text ? (
              <Container className="mt--5 mb-5" fluid>
                <Col className="info-text-on-list" lg="9">
                  <span>{this.props.text}</span>
                </Col>
              </Container>
            ) : null}
          </div>
        </>
      )
    }
  }
}

export default Header
