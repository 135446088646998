import React from 'react'
import { apiClient } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  ButtonGroup
} from 'reactstrap'
import Header from '../../../components/Headers/Header.js'
import { Select } from 'antd'
import 'antd/dist/antd.css'
import ErrorSummary, { resolveError } from '../../../components/Errors/ErrorSummary'
import { saveImage } from '../../../utils/imageHelper'
import ImageUpload from '../../../components/Upload/ImageUpload'
import EventDatePicker from '../../../components/EventDatePicker/EventDatePicker'
import disabilityImg from '../../../assets/img/event/create-feature-disability.svg'
import disabilityWcImg from '../../../assets/img/event/create-feature-dis-wc.svg'
import wcImg from '../../../assets/img/event/create-feature-wc.svg'
import hearingAidImg from '../../../assets/img/event/create-feature-hearing.svg'
import foodImg from '../../../assets/img/event/create-feature-food.svg'
import '../../../Styles.scss'

var moment = require('moment')

class EventCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: {
        title: '',
        description: '',
        start: '',
        end: '',
        startTime: '',  // Used to concatenate to date (start) when posting
        endTime: '',    // Used to concatenate to date (end) when posting
        fee: 0,
        spots: 0,
        image: '',
        link: '',        // link for digital event
        location: '',    // Address
        regionCode: '',
        eventType: "digital"
      },
      errors: null,
      regions: [],
      timeRangeErrors: false,
      dateError: false,
      locationFeatureButtons: [
        {
          key: 'Wheelchair',
          displayText: 'Handikappanpassat',
          img: disabilityImg,
          atLocation: false
        },
        {
          key: 'WheelchairWC',
          displayText: 'Handikapptoalett',
          img: disabilityWcImg,
          atLocation: false
        },
        {
          key: 'WC',
          displayText: 'Toalett',
          img: wcImg,
          atLocation: false
        },
        {
          key: 'HearingAid',
          displayText: 'Hörslinga',
          img: hearingAidImg,
          atLocation: false
        },
        {
          key: 'Food',
          displayText: 'Mat och dryck',
          img: foodImg,
          atLocation: false
        }
      ]
    }

    this.postData = this.postData.bind(this)
    this.handleInput = this.handleInput.bind(this)

    this.image = {
      base64Image: null,
      imageUrl: null
    }
  }
  
  async componentDidMount() {
    try {
      const regionsResponse = await apiClient.get('/regions')
      await this.setState({
        regions: regionsResponse.data,
        object: {
          ...this.state.object,
          regionCode: JSON.parse(sessionStorage.getItem('appUser'))?.region?.code ?? ''
        }
      })
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors
      })
    }
  }

  async postData(accessToken, data) {
    try {
      this.image = await saveImage(accessToken, this.image, '#input-file')
      if (data.eventType.toLowerCase() === 'physical') {
        if (data.location?.length > 0) {
          var locationFeatures = []
          this.state.locationFeatureButtons.forEach((button) => {
            if (button.atLocation) {
              locationFeatures.push(button.key)
            }
          })

          data.locationFeatures = locationFeatures
        } else {
          data.locationFeatures = []
        }
        data.link = null;
      } else {
        data.locationFeatures = [];
        data.location = null;
      }
      const tempData = {
        ...data,
        imagePath: this.image?.imageUrl,
        start: moment(`${data.start}T${data.startTime}`, 'YYYY-MM-DDTHH:mm:ss').toISOString(),
        end: moment(`${data.start}T${data.endTime}`, 'YYYY-MM-DDTHH:mm:ss').toISOString()
      }
      // Remove temp props
      delete tempData.startTime
      delete tempData.endTime

      await apiClient.post('/events',
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })

      this.props.history.push('/admin/events')
      window.location.reload()
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors
      })
    }
  }

  async handleInput(key, value) {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        [key]: value
      }
    }))
  }

  frmIsValid({ title, description, start, startTime, endTime, fee, spots, location, link, timeRangeError, dateError }) {
    //console.log('validation', title, description, start, startTime, endTime, fee, spots, location, link, timeRangeError, dateError)
    var haslocationOrlink = (this.state.object.eventType.toLowerCase() === 'physical' ? location?.length : link?.length);

      return title.length > 0 && description.length > 0
        && fee.toString().length > 0 && spots.toString().length > 0
        && start.length > 0 && startTime.length > 0 && endTime.length > 0 && haslocationOrlink && !timeRangeError && !dateError

    }

  toggelLocationFeature = (locationFeatureName) => {
    const items = this.state.locationFeatureButtons
    const obj = this.state.locationFeatureButtons.find(f => f.key === locationFeatureName)
    const index = this.state.locationFeatureButtons.indexOf(obj)
    items[index].atLocation = !items[index].atLocation

    this.setState({
      locationFeatureButtons: items
    })
  }

  getLocationFeatureButtons() {
    return (
      <Row>
        <Col lg="12">
          <FormGroup>
            <ButtonGroup>
              {this.state.locationFeatureButtons.map(feature => {
                return (
                  <React.Fragment>
                    <Button
                      color="primary"
                      outline
                      key={feature.key}
                      value={feature.key}
                      onClick={() => this.toggelLocationFeature(feature.key)}
                      active={feature.atLocation}
                    >
                      <img src={feature.img} />{feature.displayText}
                    </Button>
                  </React.Fragment>
                );
              })}
            </ButtonGroup>
          </FormGroup>
        </Col>
      </Row>

    );
  }

  handleEventType() {
    if (this.state.object.eventType.toLowerCase() === "digital") {
      return (
        <Row>
          <Col lg="12">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-link"
              >
                Länk (Digitalt event)
              </label>
              <Input
                className="form-control-alternative"
                id="input-link"
                type="text"
                placeholder="https://meet.google.com"
                value={this.state.object.link}
                onChange={(e) => this.handleInput('link', e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      );
    } else {
      return <>
        <Row>
          <Col lg="12">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-location"
              >
                Adress
              </label>
              <Input
                className="form-control-alternative"
                id="input-location"
                type="text"
                placeholder="Tengdahlsgatan 15, 116 51 Stockholm"
                value={this.state.object.location}
                onChange={(e) => this.handleInput('location', e.target.value)}
                
              />
            </FormGroup>
          </Col>
        </Row>
        {this.getLocationFeatureButtons()}
      </>
    }

  }

  toggelEventType = (eventTypeFilter) => {
    var obj = this.state.object;
    obj.eventType = eventTypeFilter;
    this.setState({
      object: obj
    });

  };

  render = () => {
    const { object } = this.state
    const { Option } = Select
    const isValid = this.frmIsValid({...this.state.object, timeRangeError: this.state.timeRangeError, dateError: this.state.dateError})
    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Skapa ny</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <ImageUpload />
                        <ButtonGroup>
                          <Button
                            id='buttonDigital'
                            color="primary"
                            outline
                            value='digital'
                            onClick={() => this.toggelEventType('digital')}
                            active={this.state.object.eventType.toLowerCase() === 'digital'}
                          >
                            Digitalt event
                          </Button>
                          <Button
                            id='buttonPhysical'
                            color="primary"
                            outline
                            value='physical'
                            onClick={() => this.toggelEventType('physical')}
                            active={this.state.object.eventType.toLowerCase() === 'physical'}
                          >
                            Fysiskt event
                          </Button>
                        </ButtonGroup>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="inputTitle"
                              >
                                Namnge event
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="inputTitle"
                                type="text"
                                value={object.title}
                                onChange={(e) => this.handleInput('title', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-description"
                              >
                                Om evenemang
                              </label>
                              <textarea
                                className="form-control form-control-alternative"
                                id="input-description"
                                rows="7"
                                value={object.description}
                                onChange={(e) => this.handleInput('description', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6" sm="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-region"
                              >
                                Region
                              </label>
                              <Select
                                id='input-region'
                                showSearch
                                className="form-control-alternative form-control"
                                placeholder="Sök efter region..."
                                optionFilterProp="children"
                                value={object.regionCode}
                                onChange={(value) => this.handleInput('regionCode', value)}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {this.state.regions.map((item, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <Option value={item.code}>{item.name}</Option>
                                    </React.Fragment>
                                  )
                                })}
                              </Select>
                            </FormGroup>
                          </Col>
                        </Row>
                        <EventDatePicker {...object} 
                          timeRangeError={this.state.timeRangeError}  
                          dateError={this.state.dateError}
                          onChange={this.handleInput} 
                          onDateChange={(e) => this.setState({dateError: e.error})}
                          onTimeRangeChange={(e) => this.setState({timeRangeError: e.error})}
                        />
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-fee"
                              >
                                Pris (kr)
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-fee"
                                type="number"
                                min="0"
                                placeholder="0"
                                onChange={(e) => this.handleInput('fee', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-spots"
                              >
                                Max antal (st)
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-spots"
                                type="number"
                                min="1"
                                max="9999"
                                placeholder="0"
                                onChange={(e) => this.handleInput('spots', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        {this.handleEventType()}                       
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        <Button
                          color="success"
                          type="submit"
                          onClick={(e) => { e.preventDefault(); this.postData(this.props.accessToken, this.state.object) }}
                          disabled={!isValid}
                        >
                          Skapa
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      return null
    }
  };


}

export default withRouter(EventCreate)
