import React from 'react'
import { useRouteMatch } from 'react-router-dom'

export const NavLink = ({
  children,
  to,
  className = '',
  style = {},
  activeClassName = 'active',
  basename = '',
  activeStyle = {},
  exact,
  strict
}) => {
  const active = useRouteMatch({ path: to, exact, strict })
  let styleObj = style
  if (active) {
    styleObj = { ...styleObj, ...activeStyle }
  }
  return (
    <a
      href={basename + to}
      className={'nav-link' + (className ? ` ${className}` : '') + (active ? ` ${activeClassName}` : '')}
      style={styleObj}
      area-current={active ? 'page' : ''}
    >
      {children}
    </a>
  )
}