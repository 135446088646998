import React from 'react';
import './Switch.css';

const Switch = ({ isOn, handleToggle, onColor, id, label}) => {
  return (
    <>
      <h3 className="react-switch-header">{label}</h3> 

        <input
          checked={isOn}
          onChange={handleToggle}
          className="react-switch-checkbox"
          type="checkbox"
          id={id}
        />
        <label
          style={{ background: isOn && onColor}}
          className="react-switch-label"
          htmlFor={id}
        >
          <span className={`react-switch-button`} />
        </label>
    </>
  );
};
export default Switch;
