import React from 'react'
import {
  FormGroup,
  Row,
  Col,
  Button
} from 'reactstrap'



class ImageUpload extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      imageUrl: props.imageUrl
    }
  }


  componentDidUpdate(prevProps) {
    console.log('IMAGE PROPS: ', this.props)

    if(this.props.imageUrl !== prevProps.imageUrl) {
      this.setState({ imageUrl: this.props.imageUrl  })
    }
  }

  clearImage = (event) => {
    event.preventDefault()
    document.querySelector('#input-file').value = ''
    if(this.props.onChange) { this.props.onChange() }
    this.setState({ imageUrl: null  })
  }

  handleChange = (event) => {
    let imageUrl = null
    if (event.target.files[0] !== null) {
      imageUrl = URL.createObjectURL(event.target.files[0])
      if(this.props.onChange) { this.props.onChange() }
    }
    this.setState({
      imageUrl
    })
  }

  render() {
    return (
      <>
        <Row>
          <Col lg="12">
            <label
              className="form-control-label"
              htmlFor="input-name"
            >
                Symbol
            </label>
            {this.state.imageUrl && (
              <>
                <div className="col">
                  <div className="mb-3">
                    <img className="main-content-img round-img" alt="Bild" src={`${this.state.imageUrl}`} />
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <div>
                <input id="input-file" onChange={this.handleChange} className="form-control-alternative" type="file" accept="image/*" />
                <Button
                  color="danger"
                  onClick={this.clearImage}
                  size="sm"
                  style={{ marginLeft: 10 }}
                >
                  Ta bort bild
                </Button>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </>
    )
  }
}

export default ImageUpload
