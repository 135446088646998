import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Container } from 'reactstrap'
import AdminNavbar from '../components/Navbars/AdminNavbar'
import AdminFooter from '../components/Footers/AdminFooter'
import Sidebar from '../components/Sidebar/Sidebar'

import routes from '../routes.js'

class Admin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accessToken: '',
      refreshToken: '',
      user: null,
      appUser: null
    }

    this.mainContent = React.createRef()
  }

  componentDidUpdate(e, prevProps) {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    this.mainContent.current.scrollTop = 0

    if (this.state.accessToken !== prevProps.accessToken) {
      sessionStorage.setItem(
        'accessToken',
        JSON.stringify(this.state.accessToken)
      )
    }

    if (this.state.refreshToken !== prevProps.refreshToken) {
      localStorage.setItem(
        'refreshToken',
        JSON.stringify(this.state.refreshToken)
      )
    }

    if (this.state.user !== prevProps.user) {
      sessionStorage.setItem('user', JSON.stringify(this.state.user))
    }

    if (this.state.appUser !== prevProps.appUser) {
      sessionStorage.setItem('appUser', JSON.stringify(this.state.appUser))
    }
  }

  componentDidMount() {
    const accessToken = JSON.parse(sessionStorage.getItem('accessToken'))
    const refreshToken = JSON.parse(localStorage.getItem('refreshToken'))
    const user = JSON.parse(sessionStorage.getItem('user'))
    const appUser = JSON.parse(sessionStorage.getItem('appUser'))

    if (accessToken !== null) {
      this.setState({
        accessToken: accessToken,
      })
    } else {
      this.props.history.push('/auth/login')
    }

    if (refreshToken !== null) {
      this.setState({
        refreshToken: refreshToken,
      })
    }

    if (user !== null) {
      this.setState({
        user: user,
      })
    }

    if (appUser !== null) {
      this.setState({
        appUser: appUser,
      })
    }
  }

  getRoutes = (routes, accessToken, refreshToken) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        const Component = prop.component
        return (
          <Route
            path={prop.layout + prop.path}
            render={() => (
              <Component
                accessToken={accessToken}
                refreshToken={refreshToken}
              />
            )}
            key={key}
            accessToken={accessToken}
            refreshToken={refreshToken}
            exact
          />
        )
      } else {
        return null
      }
    })
  };
  getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name
      }
    }
    return 'Brand'
  };
  render() {
    if (routes.filter(item => ('/admin' + item.path) === this.props.location.pathname && item.clean === true).length > 0) {
      return (
        <div className="main-content" ref={this.mainContent} >
          <Switch>
            {this.getRoutes(
              routes,
              this.state.accessToken,
              this.state.refreshToken
            )}
            <Redirect from="*" to="/admin/reports" />
          </Switch>
        </div>
      )
    } else {
      return (
        <>
          <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: '/admin/reports',
              imgSrc: require('../assets/img/brand/logo.png'),
              imgAlt: '...',
            }}
          />
          <div className="main-content" ref={this.mainContent} >
            <AdminNavbar
              {...this.props}
              brandText={this.getBrandText(this.props.location.pathname)}
              user={this.state.user}
              appUser={this.state.appUser}
            />
            <Switch>
              {this.getRoutes(
                routes,
                this.state.accessToken,
                this.state.refreshToken
              )}
              <Redirect from="*" to="/admin/reports" />
            </Switch>
            <Container fluid>
              <AdminFooter />
            </Container>
          </div>
        </>
      )
    }
  }
}

export default Admin
