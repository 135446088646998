import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from 'reactstrap';
import Switch from '../../../components/CheckboxSwitch/Switch'



export default function ModalHandelViolation(props, ...args) {

  const [comment, setComment] = useState('');
  const [selectedSeverity, setSeverity] = useState(props.defaultSeverity);
  const [closeThisViolation, setCloseThisViolation] = useState(false);
  const [closeAllViolation, setCloseAllViolation] = useState(false);
  const [deleteEntry, setDeleteEntry] = useState(false);
  const [saveOnlyComment, setSaveOnlyComment] = useState(false);

    
  const severities = [
    { value: "NotEvaluated", label: "Ej bedömd" },
    { value: "NotOffensive", label: "Bedöms ej som överträdelse" },
    { value: "Minor", label: "Minde överträdelse" },
    { value: "Major", label: "Grov överträdelse" }
  ];
  var tag = severities.map(s => <option key={s.value} value={s.value}>{s.label}</option>);

  const handleSaveOnlyComment = (event) => {
    console.log('only comment: ', !saveOnlyComment)
    setSaveOnlyComment(!saveOnlyComment);
  };

  const handleChangeComment = (event) => {
    setComment(event.target.value);
  };

  const handleChangeSeverity = (event) => {
    setSeverity(event.target.value);
  };

  const handleChangeCloseThis = () => {
    if (!deleteEntry) {
      setCloseThisViolation(!closeThisViolation);
    }
  }

  const handleChangeCloseAll = () => {
    if (!deleteEntry) {
      setCloseAllViolation(!closeAllViolation);
    }
  }

  const handleChangedeleteEntry = () => {
    var isSet = !deleteEntry;
    setDeleteEntry(isSet);
    if (isSet) {
      setCloseThisViolation(true)
      setCloseAllViolation(true)
    } else {
      setCloseThisViolation(false)
      setCloseAllViolation(false)
    }
  }

  return (
    <>
      <Modal isOpen={props.show} toggle={props.toggleShow} {...args}>
        <ModalHeader toggle={props.toggleShow}><h2>Hantera överträdelsen</h2></ModalHeader>
        <ModalBody>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="severity"
            >
              Bedömning
            </label>
            <Input type="select"
              onChange={handleChangeSeverity}
              multiple={false}
              defaultValue={props.defaultSeverity}
              className="dropselect_tag"
              name="severity"
              id="severity">
              {tag}
            </Input>
          </FormGroup>

          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="inputComment"
            >
              Skriv kommentar. Namn och datum läggs till automatiskt när du sparar.
            </label>
            <Input
              className="form-control-alternative"
              id="inputComment"
              type="text"
              placeholder="Ange din kommentar"
              value={comment}
              onChange={handleChangeComment}
            />
            <Switch
              label='Spara endast kommentar'
              id='onlyComment'
              onColor='#09535A'
              isOn={saveOnlyComment}
              handleToggle={handleSaveOnlyComment}
            />
          </FormGroup>
          <FormGroup>
            
            <Switch
              label='Stäng denna rapport'
              id='closeThis'
              onColor='#09535A'
              isOn={closeThisViolation}
              handleToggle={handleChangeCloseThis}
            />
          </FormGroup>
          <FormGroup>
           
            <Switch
              label='Stäng alla rapporter gällande samma inlägg'
              id = 'closeAll'
              onColor='#09535A'
              isOn={closeAllViolation}
              handleToggle={handleChangeCloseAll}
            />

          </FormGroup>
          <FormGroup>
            
            <Switch
              label='Ta bort inlägget från mötesglädje'
              id = 'deleteEntry'
              onColor='#09535A'
              isOn={deleteEntry}
              handleToggle={handleChangedeleteEntry}
            />

          </FormGroup>
        </ModalBody>
        <ModalFooter>
          
            <Button color="primary" onClick={() => {
              props.saveChatViolation({
                comment: comment,
                onlyComment: saveOnlyComment,
                severity: selectedSeverity,
                closeThis: closeThisViolation,
                closeAll: closeAllViolation,
                deleteEntry: deleteEntry
              })
              props.toggleShow()
            }
            }
            disabled={(((selectedSeverity == 'NotEvaluated' || comment?.length < 10)
              || (!closeThisViolation && !closeAllViolation)) && (!saveOnlyComment || comment?.length < 10))}
              >
            Spara ändringar
            </Button>
            {' '}
          <Button color="secondary" onClick={props.toggleShow}>
            Avbryt
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
};

