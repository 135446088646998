import React from 'react'
import { apiClient } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from 'reactstrap'
import Header from '../../../components/Headers/Header.js'
import { Select, message, Image, Comment, Tooltip, List, Pagination } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import moment from 'moment'
import 'antd/dist/antd.css'
import ErrorSummary, { resolveError } from '../../../components/Errors/ErrorSummary'
import Loader from '../../../components/Loader/Loader'
import { saveImage } from '../../../utils/imageHelper'
import ImageUpload from '../../../components/Upload/ImageUpload'
import { resolvePagingOffset } from '../../../utils/ListHelper'

class PublicChatGroupEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: null,
      regions: [],
      chatMessages: [],
      loadingChatMessages: false,
      chatMessagesLoaded: false,
      chatMessagePageIndex: 1,
      chatMessagePageSize: 10,
      chatMessagesTotalCount: 0,
      regionCode: null,
      errors: null,
      loading: true,
      notFound: false
    }

    this.getData = this.getData.bind(this)
    this.putData = this.putData.bind(this)
    this.handleInput = this.handleInput.bind(this)

    this.image = {
      changed: false,
      base64Image: null,
      imageUrl: null,
      imagePath: null
    }
    console.log('IMAGE: ', this.image)
    this.endpoint = '/public-chats'
    this.listRoute = '/admin/public-chat-groups'
    this.imagesEndpoint = '/images'
  }

  async componentDidMount() {
    const token = JSON.parse(sessionStorage.getItem('accessToken'))
    if (token) {
      await this.getData(token, this.props.match.params.chatId)
    } else {
      this.props.history.push('/auth/login')
      window.location.reload()
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if(prevState.chatMessagePageIndex !== this.state.chatMessagePageIndex) {
      this.loadChatMessages(this.props.accessToken, this.props.match.params.chatId)
    }
  }

  async getData(accessToken, membertypeId) {
    try {
      await this.setState({
        loading: true
      })
      await this.loadDropDowns()
      const response = await apiClient.get(`${this.endpoint}/${membertypeId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      await this.setState({
        object: {
          ...this.state.object,
          ...response.data,
          regionCode: response.data?.region?.code
        },
      })
      this.image.imagePath = this.state.object.imagePath
      this.image.imageUrl = this.state.object.imagePath && `${apiClient.defaults.baseURL}${this.imagesEndpoint}/${this.state.object.imagePath}` 

    } catch (error) {
      const errors = resolveError(error)
      await this.setState({
        errors,
        notFound: error?.response?.status === 404
      })
    } finally {
      await this.setState({
        loading: false
      })
    }
  }

  loadDropDowns = async () => {
    const regionsResponse = await apiClient.get('/regions')
    await this.setState({
      regions: regionsResponse.data
    })
  }

  loadChatMessages = async (accessToken, chatId) => {
    try {
      await this.setState({ loadingChatMessages: true })
      const skip = resolvePagingOffset(this.state.chatMessagePageIndex - 1, this.state.chatMessagePageSize)
      const response = await apiClient.get(`${this.endpoint}/${chatId}/admin/messages?take=${this.state.chatMessagePageSize}&skip=${skip}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      await this.setState({
        chatMessages: this.mapData(response.data.messages, apiClient),
        chatMessagesTotalCount: response.data.totalCount,
        chatMessagesLoaded: true
      })
    } catch (error) {
      message.error('Åh nej! Kunde tyvärr inte hitta meddelanden. Prova igen.', 5)
    } finally {
      await this.setState({ loadingChatMessages: false })
    }
  }

  deleteMessage = async (accessToken, chatId, messageId) => {
    try {
      await this.setState({ loadingChatMessages: true })
      await apiClient.delete(`${this.endpoint}/messages/${messageId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      // Its the last message on the page, go back one page (if not on first) and reload
      if(this.state.chatMessages.length === 1 && this.state.chatMessagePageIndex !== 1) {
        await this.setState({ chatMessagePageIndex: this.state.chatMessagePageIndex - 1})
      } else {
        await this.loadChatMessages(accessToken, chatId)
      }
    } catch (error) {
      let errorMsg = error?.response?.status === 404 ? 'Kunde tyvärr inte hitta chat-meddelandet.' : 'Ett okänt fel inträffade. Var vänlig försök igen.'
      message.error(errorMsg)
    } finally {
      await this.setState({ loadingChatMessages: false })
    }
  }

  mapData = (chatMessages, apiClient) => {
    console.log('MESSAGES: ', chatMessages)
    return chatMessages.map(message => {
      return {
        actions: [(<Tooltip key="comment-delete" title="Radera">
          <span className="comment-action" onClick={() => this.deleteMessage(this.props.accessToken, this.props.match.params.chatId, message.id)}>
            <DeleteOutlined /> Radera
          </span>
        </Tooltip>)
        ],
        author: (<span style={{ color: message.authorTotalReportedViolations > 0 ? 'red' : '' }}>{message.author.name} {message.authorTotalReportedViolations > 0 ? `(Rapporterade överträdelser: ${message.authorTotalReportedViolations})` : ''}</span>),
        //avatar: message.author.profilePicture,
        content: (<>
          <span style={{ color: message.messageTotalReportedViolations > 0 ? 'red' : '' }}>{message.messageTotalReportedViolations > 0 ? `(Rapporterat meddelande: ${message.messageTotalReportedViolations})` : ''}</span>
          <p>
            {message.text}
          </p>
          {message.imagePath && <Image height={200} width={200} src={`${apiClient.defaults.baseURL}${this.imagesEndpoint}/${message.imagePath}`} />}
        </>),
        datetime: (
          <Tooltip title={moment(message.created).format('YYYY-MM-DD HH:mm:ss')}>
            <span>{moment(message.created).format('YYYY-MM-DD HH:mm')}</span>
          </Tooltip>
        )
      }
    })
  }

  async putData(accessToken, membertypeId, data) {
    try {
      this.image = this.image.changed ? await saveImage(accessToken, this.image, '#input-file') : this.image
      const tempData = {
        ...data,
        regionCode: data?.regionCode,
        imagePath: this.image?.imageUrl
      }
      await apiClient.put(`${this.endpoint}/${membertypeId}`,
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.history.push(this.listRoute)
      window.location.reload()
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors
      })
    }
  }

  async handleInput(key, value) {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        [key]: value
      }
    }))
  }

  chatMessagePageChanged = (page, pageSize) => {
    this.setState({
      chatMessagePageIndex: page,
      chatMessagePageSize: pageSize
    })
  }

  render = () => {
    const { object } = this.state
    const { Option } = Select
    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Ändra</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <ImageUpload imageUrl={this.image?.imageUrl} onChange={() => this.image.changed = true} />
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Titel
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-name"
                                type="text"
                                value={object.title}
                                onChange={(e) => this.handleInput('title', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Beskrivning
                              </label>
                              <textarea
                                className="form-control form-control-alternative"
                                id="input-name"
                                rows="7"
                                value={object.description}
                                onChange={(e) => this.handleInput('description', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6" sm="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Region
                              </label>
                              <Select
                                showSearch
                                className="form-control-alternative form-control"
                                placeholder="Sök efter region..."
                                optionFilterProp="children"
                                value={object.regionCode}
                                onChange={(value) => this.handleInput('regionCode', value)}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {this.state.regions.map((item, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <Option value={item.code}>{item.name}</Option>
                                    </React.Fragment>
                                  )
                                })}
                              </Select>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        <Button
                          color="success"
                          type="submit"
                          onClick={(e) => { e.preventDefault(); this.putData(this.props.accessToken, this.props.match.params.chatId, this.state.object) }}
                        >
                          Spara
                        </Button>
                      </div>
                      <div className="pl-lg-4" style={{marginTop: 20}}>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <div className="d-flex justify-content-start mb-3">
                                {!this.state.chatMessagesLoaded && (
                                  <Button
                                    size="sm"
                                    color="info"
                                    type="submit"
                                    className="text-right"
                                    style={{marginRight: 5}}
                                    onClick={(e) => { e.preventDefault(); this.loadChatMessages(this.props.accessToken, this.props.match.params.chatId) }}
                                  >
                                    Ladda Chatt-meddelanden
                                  </Button>
                                )}
                              </div>
                              {this.state.loadingChatMessages && (<Loader items={5} color="danger" type="grow" size="sm" />)}
                              {this.state.chatMessagesLoaded ?
                                <>
                                  <List
                                    className="comment-list"
                                    header="Chattmeddelanden"
                                    itemLayout="horizontal"
                                    dataSource={this.state.chatMessages}
                                    renderItem={item => (
                                      <li>
                                        <Comment
                                          actions={item.actions}
                                          author={item.author}
                                          avatar={item.avatar}
                                          content={item.content}
                                          datetime={item.datetime}
                                        />
                                      </li>
                                    )}
                                  />
                                  <div className="d-flex justify-content-end mb-3">
                                    <Pagination size="small" showSizeChanger={false} hideOnSinglePage={true} total={this.state.chatMessagesTotalCount} onChange={this.chatMessagePageChanged} current={this.state.chatMessagePageIndex} pageSize={this.state.chatMessagePageSize} />
                                  </div>
                                </>
                                : null
                              }
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      if(this.state.loading) {
        return (
          <>
            <Header />
            <Container className="mt-4" fluid>
              <h3 style={{textAlign: 'center', paddingTop: 25}}>
                <Loader items={5} color="danger" type="grow" size="sm" />
              </h3>
            </Container>
          </>
        )
      } else {
        return (
          <>
            <Header redColor={true} />
            <Container className="mt-4" fluid>
              <h1>
                Åh nej!
                <br />
                {this.state.notFound ? (
                  <>Kunde tyvärr inte hitta den publika chattgruppen.</>
                ) : this.state.errors ? (
                  <ErrorSummary errors={this.state.errors} />
                ) : (
                  <>Ett okänt fel inträffade. Var vänlig försök igen.</>
                )}
              </h1>
            </Container>
          </>
        )
      }
    }
  }
}

export default withRouter(PublicChatGroupEdit)
