import React from 'react'
import { apiClient } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Table,
  Row,
  Col
} from 'reactstrap'
import Header from '../../../components/Headers/Header.js'
import 'antd/dist/antd.css'
import { Collapse, Popconfirm, message } from 'antd'
import ErrorSummary, { resolveError } from '../../../components/Errors/ErrorSummary'
import Loader from '../../../components/Loader/Loader'
import ModalHandleViolation from './ModalHandelViolation.js'
import ModalBanUser from './ModalBanUser.js'

const moment = require('moment')

class ReportEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      arrayViolations: [],
      arrayBans: [],
      object: null,
      errors: null,
      loading: true,
      notFound: false,
      showViolationPopup: false,
      showBanPopup: false,
      severities: [
        { key: "NotEvaluated",  value: "Ej bedömd" },
        { key: "NotOffensive",  value: "Bedöms ej som överträdelse" },
        { key: "Minor",         value: "Minde överträdelse" },
        { key: "Major",         value: "Grov överträdelse" }
      ],
      reasons: [
        { key: "Other", value: "Annan anledning" },
        { key: "CodeOfConductViolation", value: "Regelbrott" },
        { key: "IllegalBehavior", value: "Olagligt beteende" }
      ],
      reportTypes: [
        { key: "Event", value: "Event" },
        { key: "ChatMessage", value: "Chat-meddelande" }
      ],
      hasActiveBan: false,
      codeOfConductViolationId: null
    }

    this.getData = this.getData.bind(this)
    this.banUser = this.banUser.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.onSort = this.onSort.bind(this)
    this.saveChatViolation = this.saveChatViolation.bind(this)
    this.endpoint = '/chat-violations/reports'
    this.listRoute = '/admin/reports'

    this.violationChatEditEndpoint = '/chat-violations'
    this.violationEventEditEndpoint = '/event-violations'
    this.violationChatMessageEndpoint = '/chat-violations/chat-messages'
    this.violationEventEndpoint = '/event-violations/events'
    this.userBanEndpoint = '/users/ban'
    this.deleteChatMessageEndpoint = '/private-chats/messages'
  }

  async componentDidMount() {
    const token = JSON.parse(sessionStorage.getItem('accessToken'))
    if (token) {
      this.setState({ codeOfConductViolationId: this.props.match.params.violationId })
      await this.getData(token, this.props.match.params.violationId)
    } else {
      this.props.history.push('/auth/login')
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    console.log('PROPS:', this.props)
    if ((this.state.codeOfConductViolationId !== prevState.codeOfConductViolationId) ) {
      await this.getData(this.props.accessToken, this.state.codeOfConductViolationId)
    }


  }

  async getData(accessToken, violationId) {
    try {
      await this.setState({
        loading: true
      })
      const response = await apiClient.get(`${this.endpoint}/${violationId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      await this.setState({
        object: {
          ...this.state.object,
          ...response.data,
        },
      })
      await this.setState({
        arrayViolations: response.data.allViolationsForAuthor,
        arrayBans: response.data.allBanningsOfAuthor,
        hasActiveBan: response.data.allBanningsOfAuthor.filter(b => b.isBanned).length > 0 ? true : false
      })
      { console.log('DATA:', response.data, 'BANNED:', this.state.hasActiveBan, 'OBJ:', response.data.allBanningsOfAuthor.filter(b => b.isBanned)) }
    } catch (error) {
      const errors = resolveError(error)
      await this.setState({
        errors,
        notFound: error?.response?.status === 404
      })
    } finally {
      await this.setState({
        loading: false
      })
    }
  }

  async handleInput(key, value) {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        [key]: value
      }
    }))
  }

  async onSort(user, sortKey) {
    let desc = this.state.desc === null ? false : !this.state.desc
    await this.setState({
      orderBy: sortKey,
      desc,
    })
  }

  async navigate(item) {
    this.props.history.push(`${this.listRoute}/${item.codeOfConductViolationId}`)
    this.setState({ codeOfConductViolationId: item.codeOfConductViolationId })
  }

  renderSortIcon(columnName) {
    return this.state.orderBy === columnName ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null
  }

  async saveChatViolation(violationEdit) {
    console.log('ViolationEdit', violationEdit, this.state.object)

    const token = JSON.parse(sessionStorage.getItem('accessToken'))

    if (token) {


      try {
        const tempData = {
          comment: violationEdit.comment,
          severity: violationEdit.severity,
          isActive: !violationEdit.closeThis,
        }
        console.log('TEMPDATA:', tempData)
        console.log('REPORT TYPE:', this.state.object.reportType)



        if (this.state.object.reportType == 'Event') {
          await apiClient.put(`${this.violationEventEditEndpoint}/edit/${this.state.object.codeOfConductViolationId}`,
            tempData,
            {
              headers: { Authorization: `Bearer ${token}`, },
            });
          if (violationEdit.closeAll) {
            await apiClient.put(`${this.violationEventEndpoint}/close-all-violations/${this.state.object.reportedEntry}`,
              tempData,
              {
                headers: { Authorization: `Bearer ${token}`, },
              });
          }

          if (violationEdit.deleteEntry) {
            await apiClient.delete(`${this.deleteChatMessageEndpoint}/${this.state.object.entryId}`,
              {
                headers: { Authorization: `Bearer ${token}`, },
              });
          }
        }
        else {
          await apiClient.put(`${this.violationChatEditEndpoint}/edit/${this.state.object.codeOfConductViolationId}`,
            tempData,
            {
              headers: { Authorization: `Bearer ${token}`, },
            });

          if (violationEdit.closeAll) {
            await apiClient.put(`${this.violationChatMessageEndpoint}/close-all-violations/${this.state.object.entryId}`,
              tempData,
              {
                headers: { Authorization: `Bearer ${token}`, },
              });
          }

          if (violationEdit.deleteEntry) {
            await apiClient.delete(`${this.deleteChatMessageEndpoint}/${this.state.object.reportedEntityId}`,
              {
                headers: { Authorization: `Bearer ${token}`, },
              });
          }
        }
        this.getData(token, this.state.object.codeOfConductViolationId);

      } catch (error) {
        const errors = resolveError(error)
        this.setState({
          errors
        })
      }

    } else {
      this.props.history.push('/auth/login')
    }
  }

  async banUser(banInfo) {
    const token = JSON.parse(sessionStorage.getItem('accessToken'))
    if (token) {

      try {
        var userId = this.state.object.authorId
        const tempData = {
          isLongTimeBan: banInfo.isLongTimeBan,
          codeOfConductViolationId: this.state.object.codeOfConductViolationId,
          comment: banInfo.comment
        };
        console.log('ABOUT TO BAN:', userId , tempData)
        await apiClient.post(`${this.userBanEndpoint}/${userId}`,
          tempData,
          {
            headers: { Authorization: `Bearer ${token}`, },
          });
        this.getData(token, this.state.object.codeOfConductViolationId);
      } catch (error) {
        const errors = resolveError(error)
        this.setState({
          errors
        })
      }

    } else {
      this.props.history.push('/auth/login')
    }
  }

  render = () => {
    const { object } = this.state
    const { Panel } = Collapse
    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0" >
                    <Row className="align-items-center" >
                      <Col xs="4">
                        <h3 className="mb-0" >Rapporterad överträdelse</h3>
                      </Col>{object.isActive
                        ? <></>
                        : <>
                          <Col xs="4" style={{ backgroundColor: '#09535A', color: 'white', textAlign: 'center'} }>
                            {'HANTERAD av '}{object.lastUpdatedByName}{' den '}{moment(object.lastUpdated).format('YYYY-MM-DD')}
                          </Col>
                        </>}
                      {this.state.hasActiveBan
                        ? <>
                          <Col lg="2" style={{ backgroundColor: '#A90505', color: 'white', textAlign: 'center' }}>ANVÄNDAREN ÄR AVSTÄNGD</Col>
                        </>
                        : <></>}
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>

                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12" style={{ backgroundColor: 'lightgray' }}>
                            <h4 className="heading-small mb-4" >
                              Anmäld användare
                            </h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="avatarAuthor"
                              >
                                Avatar
                              </label>{object.authorProfilePicture && (
                                <>
                                  <div className="col" id="avatarAuthor">
                                    <div className="mb-3">
                                      <img
                                        className="main-content-img round-img"
                                        alt={object.authorProfilePicture}
                                        src={apiClient.defaults.baseURL + '/images/' + object.authorProfilePicture}
                                        style={{ wordWrap: 'break-word', aspectRatio: '1' }} />
                                    </div>
                                  </div>
                                </>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>

                              <label
                                className="form-control-label"
                                htmlFor="authorname"
                              >
                                Namn
                              </label>
                              <div id="authorname">{object.authorName}</div>
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>

                              <label
                                className="form-control-label"
                                htmlFor="authorphone"
                              >
                                Telefonnummer
                              </label>
                              <div id="authorphone">{object.authorPhoneNumber}</div>
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="authorid"
                              >
                                Id
                              </label>
                              <div id="authorid">{object.authorId}</div>
                            </FormGroup>
                          </Col>

                        </Row>

                        <Row>
                          <Col lg="10">
                            <FormGroup>
                              <div className="d-flex justify-content-between mb-3">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-name"
                                >
                                  Totalt antal anmälningar ({object.totalViolations})
                                </label>

                              </div>

                            </FormGroup>
                          </Col>
                          {!this.state.hasActiveBan
                            ? <>
                              <Col lg="2">
                                <React.Fragment>
                                  <Button
                                    color="primary"

                                    key='handleBan'
                                    value=''
                                    onClick={() => this.setState({ showBanPopup: !this.state.showBanPopup })}
                                  >
                                    Stäng av användaren
                                  </Button>
                                  <ModalBanUser
                                    show={this.state.showBanPopup}
                                    toggleShow={() => this.setState({ showBanPopup: !this.state.showBanPopup })}
                                    banUser={this.banUser}
                                     />
                                </React.Fragment>

                              </Col>
                            </>
                            : <></>
                          }
                        </Row>

                        <Row >
                          <Col lg="12" style={{ backgroundColor: 'lightgray', display: 'flex', marginright: '20px'}}>
                            <h4 className="heading-small mb-4" >
                              Uppgifter
                            </h4>
                            <pre >  -  rapporterad typ: {this.state.reportTypes.map(s => s.key == object.reportType ? s.value : '')}</pre>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <FormGroup>

                              <label
                                className="form-control-label"
                                htmlFor="entrytext"
                              >
                                Text
                              </label>
                              <div id="entrytext">
                                {object.text}
                              </div>
                            </FormGroup>
                          </Col>

                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="entryimage"
                              >
                                Bild
                              </label>
                              {object.picturePath ? (
                                <>
                                  <div className="col" id="entryimage">
                                    <div className="mb-3">
                                      <img
                                        className="main-content-img round-img"
                                        alt={object.picturePath}
                                        src={apiClient.defaults.baseURL + '/images/' + object.picturePath}
                                        style={{ aspectRatio: '1', height: '125px' }} />
                                    </div>
                                  </div>
                                </>
                              ) : <div id="entryimage">Ingen bild</div>}
                            </FormGroup>
                          </Col>

                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="reason"
                              >
                                Anledning
                              </label>
                              <div id="reason">
                                {this.state.reasons.map(s => s.key == object.reason ? s.value : '')}
                              </div>
                            </FormGroup>
                          </Col>

                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="severity"
                              >
                                Bedömning
                              </label>
                              <div id="severity">
                                {this.state.severities.map(s => s.key == object.severity ? s.value : '')}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row >
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="comment"
                              >
                                Kommentarer
                              </label>
                              <div id="comment">
                                <pre>{object.comment}</pre>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>

                        {object.isActive
                          ? <>
                            <Row>
                              <Col lg="10"></Col>
                              <Col lg="2">
                                <React.Fragment>
                                  <Button
                                    color="primary"

                                    key='handleViolation'
                                    value=''
                                    onClick={() => this.setState({ showViolationPopup: !this.state.showViolationPopup })}
                                  >
                                    Hantera rapport
                                  </Button>
                                  <ModalHandleViolation
                                    show={this.state.showViolationPopup}
                                    toggleShow={() => this.setState({ showViolationPopup: !this.state.showViolationPopup })}
                                    saveChatViolation={this.saveChatViolation}
                                    defaultSeverity={object.severity} />
                                </React.Fragment>
                              </Col>                            
                            </Row>
                          </>
                          : <></>}
                        <Row>
                          <Col lg="12" style={{ backgroundColor: 'lightgray' }}>
                            <h4 className="heading-small mb-4">
                              Anmäld av
                            </h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="reportername"
                              >
                                Namn
                              </label>
                              <div id="reportername">
                                {object.reporterName}
                              </div>
                            </FormGroup>
                          </Col>

                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="reporterid"
                              >
                                Id
                              </label>
                              <div id="reporterid">
                                {object.reporterId}
                              </div>
                            </FormGroup>
                          </Col>

                        </Row>
                      </div>

                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12" style={{ backgroundColor: 'lightgray' }}>
                            <h6 className="heading-small  mb-4">
                              Anmälningshistorik
                            </h6>
                          </Col>
                        </Row>
                        {object.totalViolations > 1 ?
                          <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th className="sortable" onClick={e => this.onSort(e, 'reportType')} scope="col">Typ {this.renderSortIcon('reportType')}</th>
                                <th className="sortable" onClick={e => this.onSort(e, 'severity')} scope="col">Bedömning {this.renderSortIcon('severity')}</th>
                                <th className="sortable" onClick={e => this.onSort(e, 'reason')} scope="col">Anledning {this.renderSortIcon('reason')}</th>
                                <th className="sortable" onClick={e => this.onSort(e, 'createdDt')} scope="col">Datum {this.renderSortIcon('createdDt')}</th>
                                <th scope="col">Kommentar </th>
                                <th scope="col">Anmält meddelande </th>
                                <th scope="col">Bild </th>
                                <th scope="col" />
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.arrayViolations.length > 0
                                ? this.state.arrayViolations.map((item, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr className="clickable" style={item.isActive ? { backgroundColor: '#0040DD', color: 'white' } : {}}>
                                        <td onClick={() => { this.navigate(item) }}>
                                          <span className="mb-0 text-sm">
                                            {this.state.reportTypes.map(s => s.key == item.reportType ? s.value : '')}
                                          </span>
                                        </td>
                                        <td onClick={() => { this.navigate(item) }}>
                                          <span className="mb-0 text-sm">
                                            {this.state.severities.map(s => s.key == item.severity ? s.value : '')}
                                           </span>
                                        </td>
                                        <td onClick={() => { this.navigate(item) }}>
                                          <span className="mb-0 text-sm">
                                            {this.state.reasons.map(s => s.key == item.reason ? s.value : '')}
                                          </span>
                                        </td>
                                        <td onClick={() => { this.navigate(item) }}>
                                          <span className="mb-0 text-sm">
                                            {new Date(item.createdDt).toLocaleDateString('sv-SE', {
                                              year: 'numeric', month: 'numeric', day: 'numeric'
                                            }).replace(/ /g, '-')}
                                          </span>
                                        </td>
                                        <td onClick={() => { this.navigate(item) }} style={{ width: '150px', wordWrap: 'break-word' }}>
                                          <span className="mb-0 text-sm" style={{ width: '150px', wordWrap: 'break-word' }}>
                                            {item.comment}
                                          </span>
                                        </td>
                                        <td onClick={() => { this.navigate(item) }} style={{ width: '150px', wordWrap: 'break-word' }}>
                                          <span className="mb-0 text-sm" style={{ width: '150px', wordWrap: 'break-word' }}>
                                            {item.chatMessageText}
                                          </span>
                                        </td>
                                        <td onClick={() => { this.navigate(item) }}>
                                          <span className="mb-0 text-sm">
                                            {item.chatMessagePicture ? <img className="img-in-listtable" alt="Bild" src={apiClient.defaults.baseURL + '/images/' + item.chatMessagePicture} style={{ aspectRatio: '1', height: '75px' }} /> : null}
                                            {console.log('API-Klient: ', apiClient.defaults.baseURL)}
                                          </span>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  )
                                })
                                : null}
                            </tbody>
                          </Table>
                          :
                          <h4> Första förseelsen. Ingen historik finns.</h4>
                        }
                      </div>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12" style={{ backgroundColor: 'lightgray' }}>
                            <h4 className="heading-small  mb-4">
                              Utfärdade avstängningar
                            </h4>
                          </Col>
                        </Row>
                        {object.totalNoOfBan > 0 ?
                          <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th onClick={e => this.onSort(e, 'phoneNumber')} scope="col">Aktuellt telefonnummer {this.renderSortIcon('phoneNumber')}</th>
                                <th onClick={e => this.onSort(e, 'bannedByName')} scope="col">Avstängd av {this.renderSortIcon('bannedByName')}</th>
                                <th onClick={e => this.onSort(e, 'comment')} scope="col">Anledning {this.renderSortIcon('comment')}</th>
                                <th onClick={e => this.onSort(e, 'dateOfBan')} scope="col">Datum för avst. {this.renderSortIcon('dateOfBan')}</th>
                                <th onClick={e => this.onSort(e, 'endOfBanDate')} scope="col">Avstängd t.o.m. {this.renderSortIcon('endOfBanDate')}</th>
                                <th scope="col" />
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.arrayBans.length > 0
                                ? this.state.arrayBans.map((item, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr style={item.isBanned ? { color: 'red' } : {}}>
                                        <td >
                                          <span className="mb-0 text-sm">
                                            {item.phoneNumber}
                                          </span>
                                        </td>
                                        <td >
                                          <span className="mb-0 text-sm">
                                            {item.bannedByName}
                                          </span>
                                        </td>
                                        <td >
                                          <span className="mb-0 text-sm">
                                            {item.comment}
                                          </span>
                                        </td>
                                        <td >
                                          <span className="mb-0 text-sm">
                                            {new Date(item.dateOfBan).toLocaleDateString('sv-SE', {
                                              year: 'numeric', month: 'numeric', day: 'numeric'
                                            }).replace(/ /g, '-')}
                                          </span>
                                        </td>
                                        <td >
                                          <span className="mb-0 text-sm">
                                            {new Date(item.endOfBanDate).toLocaleDateString('sv-SE', {
                                              year: 'numeric', month: 'numeric', day: 'numeric'
                                            }).replace(/ /g, '-')}
                                          </span>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  )
                                })
                                : null}
                            </tbody>
                          </Table>
                          :
                          <h4> Inga tidigare åtgärder finns.</h4>
                        }
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      {/*{ !object.userName.startsWith('Raderat konto') && (*/}
                      {/*  <div className="pl-lg-4">*/}
                      {/*    <Popconfirm*/}
                      {/*      placement="topLeft" title='Är du säker på att du vill radera användaren?'*/}
                      {/*      onConfirm={(e) => { e.preventDefault(); this.deleteUser(this.props.accessToken, this.props.match.params.userId) }}*/}
                      {/*      okText="Ja" */}
                      {/*      okType="danger"*/}
                      {/*      cancelText="Avbryt"*/}
                      {/*    >*/}
                      {/*      <Button color="danger" type="submit" >*/}
                      {/*      Radera användare*/}
                      {/*      </Button>*/}
                      {/*    </Popconfirm>*/}
                      {/*  </div>*/}
                      {/*)}*/}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      if (this.state.loading) {
        return (
          <>
            <Header />
            <Container className="mt-4" fluid>
              <h3 style={{ textAlign: 'center', paddingTop: 25 }}>
                <Loader items={5} color="danger" type="grow" size="sm" />
              </h3>
            </Container>
          </>
        )
      } else {
        return (
          <>
            <Header redColor={true} />
            <Container className="mt-4" fluid>
              <h1>
                Åh nej!
                <br />
                {this.state.notFound ? (
                  <>Kunde tyvärr inte hitta den aktuella anmälan</>
                ) : this.state.errors ? (
                  <ErrorSummary errors={this.state.errors} />
                ) : (
                  <>Ett okänt fel inträffade. Var vänlig försök igen.</>
                )}
              </h1>
            </Container>
          </>
        )
      }
    }
  }
}

export default withRouter(ReportEdit)
