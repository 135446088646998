import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from 'reactstrap';
import Switch from '../../../components/CheckboxSwitch/Switch'



export default function ModalBanUser(props, ...args) {

  const [comment, setComment] = useState('');
  const [banTime, setBanTime] = useState('shortTime');
  const [doBanUsern, setBanUser] = useState(false);


  const handleChangeComment = (event) => {
    setComment(event.target.value);
  };

  const toggelBanTime = (event) =>  {
    setBanTime(event.target.value);
  };


  //console.log('PROPS:', props, 'INPUTS:', 'comment', comment, 'banTime', banTime, 'doBan', doBanUsern);


  return (
    <>
      <Modal isOpen={props.show} toggle={props.toggleShow} {...args}>
        <ModalHeader toggle={props.toggleShow}><h2>Stäng av användare</h2></ModalHeader>
        <ModalBody>
          <FormGroup>
            <ButtonGroup>

              <React.Fragment>
                <Button
                  color="primary"
                  outline
                  key='shortTime'
                  value='shortTime'
                  onClick={toggelBanTime}
                  active={banTime == 'shortTime'}
                >
                  Temporär avstängning (14 dagar)
                </Button>
                <Button
                  color="primary"
                  outline
                  key='longTime'
                  value='longTime'
                  onClick={toggelBanTime}
                  active={banTime == 'longTime'}
                >
                  Lång avstängning (6 månader)
                </Button>
              </React.Fragment>

            </ButtonGroup>
          </FormGroup>

          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="inputComment"
            >
              Skriv kommentar (obligatoriskt). Namn och datum läggs till automatiskt när du sparar.
            </label>
            <Input
              className="form-control-alternative"
              id="inputComment"
              type="text"
              placeholder="Ange din kommentar"
              value={comment}
              onChange={handleChangeComment}
            />
          </FormGroup>
          <FormGroup>
            
            <Switch
              id='banUser'
              label= 'Bekräfta avstängning'
              onColor='#A90505'
              isOn={doBanUsern}
              handleToggle={() => setBanUser(!doBanUsern)}
            />

          </FormGroup>
        </ModalBody>
        <ModalFooter>
          
          <Button color="primary"
            onClick={() => {
              props.banUser({ comment: comment, isLongTimeBan: banTime == 'longTime' })
              props.toggleShow()
            }
            }
            disabled={!doBanUsern || comment?.length < 10}
          >
              Stäng av användaren
           </Button>{' '}
          
            
          
          <Button color="secondary" onClick={props.toggleShow}>
            Avbryt
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
};

