import { initializeApp } from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyD4cwCguDLcWfiN6X9LO9hRo4r4j_5gUmM',
  authDomain: 'motesgladje.firebaseapp.com',
  projectId: 'motesgladje',
  storageBucket: 'motesgladje.appspot.com',
  messagingSenderId: '213676802713',
  appId: '1:213676802713:web:ca90470aa7b02f0e932e08',
  measurementId: 'G-W02NHXF6DW'
}

const firebaseApp = initializeApp(firebaseConfig)

export { firebaseApp }
