// Login
import Login from './views/endpoints/Login'

// Events
import EventsList from './views/endpoints/Events/List'
import EventCreate from './views/endpoints/Events/Create'
import EventEdit from './views/endpoints/Events/Edit'
// Public Chat Groups
import PublicChatGroupsList from './views/endpoints/PublicChatGroups/List'
import PublicChatGroupCreate from './views/endpoints/PublicChatGroups/Create'
import PublicChatGroupEdit from './views/endpoints/PublicChatGroups/Edit'
// Reports
import ReportsList from './views/endpoints/Reports/List'
import ReportEdit from './views/endpoints/Reports/Edit'
// Admins
import AdminUsersList from  './views/endpoints/AdminUsers/List'

var routes = [
  {
    menu: false,
    clean: false,
    path: '/login',
    name: 'Logga in',
    component: Login,
    layout: '/auth',
  },

  {
    menu: true,
    clean: false,
    path: '/events',
    name: 'Event',
    component: EventsList,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/events/create',
    component: EventCreate,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/events/:eventId',
    component: EventEdit,
    layout: '/admin',
  },
  {
    menu: true,
    clean: false,
    path: '/public-chat-groups',
    name: 'Chattgrupper',
    component: PublicChatGroupsList,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/public-chat-groups/create',
    component: PublicChatGroupCreate,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/public-chat-groups/:chatId',
    component: PublicChatGroupEdit,
    layout: '/admin',
  },
  {
    menu: true,
    clean: false,
    path: '/reports',
    name: 'Rapporter',
    component: ReportsList,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/reports/:violationId',
    component: ReportEdit,
    layout: '/admin',
  },
  {
    menu: true,
    clean: false,
    path: '/admin-users',
    name: 'Administratörer',
    component: AdminUsersList,
    layout: '/admin',
  }
]
export default routes
