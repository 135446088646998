import { apiClient } from './api-config'

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result.substr(reader.result.indexOf(',') + 1))
  reader.onerror = error => reject(error)
})


const saveImage = async (accessToken, image, inputFileId) => {
  try {
    let file = document.querySelector(inputFileId).files[0]
    var savedImage = {
      imageUrl: null,
      base64Image: null
    }
    if(!file) { return savedImage }

    const fileBase64 = await toBase64(file)
    if(fileBase64 === image.base64Image) { return image }
    savedImage.base64Image = fileBase64

    const response = await apiClient.post('/images/base64',
      { base64EncodedData: fileBase64 },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    savedImage.imageUrl = response.headers.location
    return savedImage
  } catch(error) {
    const errors = error?.response?.data?.errors ?? 'There was an error saving the image'
    throw new Error(errors)
  }
}

export { toBase64, saveImage }
