/*eslint-disable*/
import React from "react";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

class Footer extends React.Component {
  render() {
    const year = new Date().getFullYear()
    return (
      <footer className="footer">
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">
              © {year}
            </div>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
