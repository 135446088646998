import React from 'react'
import { Link } from 'react-router-dom'
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from 'reactstrap'

var gravatar = require('gravatar')

class AdminNavbar extends React.Component {
  logout() {
    sessionStorage.clear()
    localStorage.clear()
    this.props.history.push('/auth/login')
    window.location.reload()
  }

  renderUserName = (externalUser, appUser) => {
    let resolvedUserName = (externalUser && externalUser.firstName) ?? (appUser && appUser.firstName) 
    if(resolvedUserName) {
      return resolvedUserName
    }

    return 'Hej'
  }

  renderAvatar = (externalUser, appUser) => {
    // try use gravatar
    if(externalUser && externalUser.email)  {
      return gravatar.url(externalUser.email)
    }

    // try use appUser profilePicture
    if(appUser && appUser.profilePicture)  {
      return appUser.profilePicture
    }

    // default to defined asset picture
    return require('../../assets/img/picture.png')
  }

  render() {
    
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    {/*<span className="avatar avatar-sm rounded-circle">*/}
                    {/*  <img src={this.renderAvatar(this.props.user, this.props.appUser)} alt="Avatar" />*/}
                    {/*</span>*/}
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.renderUserName(this.props.user, this.props.appUser)}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem onClick={() => this.logout()}>
                    <i className="ni ni-user-run" />
                    <span>Logga ut</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    )
  }
}

export default AdminNavbar
